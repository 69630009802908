<script>
import Settlement from '../report/settlement-daily'
export default {
  props: {
    user: Object
  },
  components: {
    Settlement
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div>
    <Settlement :user="user" :search-by-user="true" />
  </div>
</template>
