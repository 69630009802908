<script>
import detailReportSettlement from './detail-report-settlement'

export default {
  props: {
    user: Object
  },
  components: {
    detailReportSettlement
  },
  data() {
    return {
      content: 'detailReportSettlement',
    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div class="shadow shadow-sm">
    <div class="p-2">
      <detailReportSettlement :user="user"></detailReportSettlement>
    </div>
  </div>
</template>
