<script>
import detailTransactionAll from './detail-transaction-all'
import detailTransactionGame from './detail-transaction-game'

export default {
  props: {
    user: Object,
    contentChild: String
  },
  components: {
    detailTransactionAll,
    detailTransactionGame
  },
  data() {
    return {
      content: this.contentChild,
    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div class="shadow shadow-sm">
    <div class="p-2">

      <component :is="contentChild" :user="user" :searchByUser="true" v-if="user"></component>

      <!-- <detailTransactionAll :user="user" :searchByUser="true"></detailTransactionAll> -->
    </div>
  </div>
</template>
