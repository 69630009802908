<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"

import detailTransaction from '../../components/user/detail-transaction'
import detailReport from '../../components/user/detail-report'

export default {
  components: {
    Layout,
    PageHeader,
    detailTransaction,
    detailReport
  },
  data() {
    return {
      content: 'detailReport',
      contentChild: 'detailTransactionAll',
      user: null,
      id: 0
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.init().then()
  },
  methods: {
    init: async function() {
      const result = await this.$API.user.getUser(this.id)
      if (result.code !== 0) {
        return alert(result.msg)
      }
      this.user = result.document
    }
  }
};
</script>

<template>
  <div class="container-fluid mt-2">
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow shadow-lg">
          <div class="card-header" v-if="user">
            <div>






              <span class="badge badge-pill badge-soft-primary me-1" v-if="user.top &&  ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')">{{ $t('본사') }}-{{ user.top.username }}</span>
              <span class="badge badge-pill badge-soft-success me-1" v-if="user['super'] && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')">{{ $t('부본사') }}-{{ user['super'].username }}</span>
              <span class="badge badge-pill badge-soft-info me-1" v-if="user.master && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')">{{ $t('지사') }}-{{ user.master.username }}</span>
              <span class="badge badge-pill badge-soft-warning me-1" v-if="user.agent && ($store.getters['auth/user'].level != 'shop')">{{ $t('총판') }}-{{ user.agent.username }}</span>
              <span class="badge badge-pill badge-soft-dark" v-if="user.shop">{{ $t('매장') }}-{{ user.shop.username }}</span>
            </div>
            <h4 class="card-title" v-if="user"><span class="badge bg-secondary">{{ $t('회원') }}</span> {{ $t('user_info', [user.username])}}
              <small>
                (<span>{{ $t('캐쉬') }}: {{ user.cash.floatVal().formatThousands() }}</span>)
              </small>
            </h4>
          </div>
          <div class="card-body">
            <div class="card shadow shadow-sm">
              <div class="card-header pb-0">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link" :class="{active: content==='detailReport'}" href="#" @click="content='detailReport'">{{ $t('보고서') }}</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :class="{active: content==='detailTransaction' && contentChild==='detailTransactionAll'}" href="#" @click="content='detailTransaction'; contentChild='detailTransactionAll';">{{ $t('전체거래') }}</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :class="{active: content==='detailTransaction' && contentChild==='detailTransactionGame'}" href="#" @click="content='detailTransaction'; contentChild='detailTransactionGame';">{{ $t('게임거래내역') }}</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <component :is="content" :user="user" :contentChild="contentChild" v-if="user"></component>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
  </div>
</template>
