<script>
import Game from '../transaction/game'
export default {
  props: {
    user: Object
  },
  components: {
    Game
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<template>
  <div>
    <Game :user="user" :searchByUser="true" />
  </div>
</template>
